import React, { Component } from 'react';
import FirmwareForm from './forms/FirmwareForm';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { uploadFile, fetchFiles } from '../actions/firmwareAction';
import { showModal, hideModal } from '../actions/modalAction';
import ReactTable from "react-table";
import moment from 'moment';

class FirmwareAdmin extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.fetchFiles();
  }

  renderLoading = () => {
    return (
      <div className="text-center">
        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
      </div>
    )
  }

  renderTable = (data) => {
    return (
      <div>
        <button className="btn btn-primary pull-right" type="button" onClick={() => this.props.showModal('Firmware', 'CREATE_ENTITY')}><i className="fa fa-plus icon" />&nbsp;Upload Firmware</button>
        <br />
        <br />
        <ReactTable
            data={data}
            noDataText="No matching firmware found"
            columns={[
              {
                Header: "Firmware Name",
                id: "file",
                accessor: "name",
                filterable: true,
                filterMethod: (filter, row) => {
                  return row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
                },
                headerClassName: 'header'
              },
              {
                Header: "Version",
                id: "version",
                accessor: "version",
                filterable: true,
                headerClassName: 'header'
              },
              {
                Header: "Uploaded At",
                id: "created_at",
                accessor: d => {
                  return moment(d.created_at).format('lll')
                },
                headerClassName: 'header'
              },
              {
                maxWidth: 20,
                headerClassName: 'header',
                Cell: row => (
                  <div className="pull-right">
                    <i className="fa fa-times fa-lg icon pull-right" onClick={(data, type) => this.props.showModal({ id: row.original.id, name: row.original.name, group: 'Firmware' }, 'REMOVE_ENTITY')} />
                  </div>
                )
              }
            ]}
            defaultPageSize={15}
            showPageSizeOptions={false}
            getNoDataProps={() => {return {
              style: { backgroundColor: '#f0f0f0', fontWeight: 'bold'}
            }}}
            className="-striped -highlight"
          />
        </div>
    )
  }

  render() {
    return (
      <div>
        <div className="panel-body">
          {this.props.isLoading ? this.renderLoading() : Object.keys(this.props.firmwares).length > 0 ? this.renderTable(this.props.firmwares) : <FirmwareForm /> }
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { firmwares, isLoading } = { ...state.firmwares }
  return {
    firmwares,
    isLoading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchFiles: () => { dispatch(fetchFiles()) },
    uploadFile: (file) => { dispatch(uploadFile(file)) },
    showModal: (data, type) => { dispatch(showModal(data, type)) },
    hideModal: () => { dispatch(hideModal()) }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FirmwareAdmin));
