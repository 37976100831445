import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Route, Router } from 'react-router-dom';
import App from './App';
import LoginForm from '../components/forms/LoginForm';
import PasswordReset from '../components/forms/PasswordReset';
import ForgotPassword from '../components/forms/ForgotPassword';
import Notification from '../components/Notification';
import ModalRoot from '../components/ModalRoot';
import history from './history';

const Root = ({ store }) => (
    <Provider store={store}>
        <Router history={history}>
            <div>
                <ModalRoot />
                <Notification />
                <Route exact path='/' component={App} />
                <Route exact path='/login' component={LoginForm} />
                <Route exact path='/passwordreset/:token' component={PasswordReset} />
                <Route exact path='/forgotpassword' component={ForgotPassword} />
            </div>
        </Router>
    </Provider>
);


Root.propTypes = {
    store: PropTypes.object.isRequired
};

export default Root;