import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import LoginForm from '../components/forms/LoginForm';
import Main from '../components/Main';
import { isLoggedIn } from '../utils/AuthService';

class App extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className="jumbotron app-background">
                    {isLoggedIn() ? <Main {...this.props} /> : <Redirect to={{ pathname: '/login'}} /> }
                </div>
            </div>
        );
    }
}

export default withRouter(connect(null, null)(App));