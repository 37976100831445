import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import CreateModal from './modals/CreateModal';
import UpdateModal from './modals/UpdateModal';
import RemovaModal from './modals/RemoveModal';

const MODAL_COMPONENTS = {
  'CREATE_ENTITY': CreateModal,
  'UPDATE_ENTITY': UpdateModal,
  'REMOVE_ENTITY': RemovaModal
}

const ModalRoot = ({modalType, modalProps}) => {
  if(!modalType){
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[modalType]
  return <SpecificModal {...modalProps} />
}

const mapStateToProps = state => {
  const { modalType, modalProps } = {...state.modals};
  return { modalType, modalProps }
}

export default connect(mapStateToProps)(ModalRoot);
