import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Panel, FormControl } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { forgotPassword } from '../../actions/loginAction';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = { email: '' }
  }

  handleInputChange = e => {
    return this.setState({ email: e.target.value });
  }

  render() {
    return (
      <Panel header='Forgot Password' bsStyle='info' className="login forgot-password">
        <form id="forgot-password" onSubmit={e => {
          e.preventDefault();
          
          this.props.forgotPassword(this.state);

          e.target.reset();
        }}>
          <FormControl type='email'
            name='email'
            label='Email'
            placeholder='Email'
            value={this.state.email}
            onChange={(e) => this.handleInputChange(e)} />
          <br />
          <button className='btn btn-primary'>Submit</button>
        </form>
      </Panel>
    )
  }
}

ForgotPassword.propTypes = {
  forgotPassword: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => {
  return {
    forgotPassword: (email) => { dispatch(forgotPassword(email)) }
  }
}

export default withRouter(connect(null, mapDispatchToProps)(ForgotPassword));
