import { UPLOAD_FILE, FETCH_FILES, REMOVE_FILE, REQUEST_FILE } from '../actions/constants';
import { addNotification } from './notificationAction';
import { getUserToken } from '../utils/AuthService';
import axios from 'axios';

const token = document.querySelector("meta[name=csrf-token]").content;
const bearer = getUserToken();

axios.defaults.baseURL = '/api/';
axios.defaults.headers.common['X-CSRF-Token'] = token;
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Authorization'] = `Bearer ${bearer}`;

export const fetchFiles = () => {
  const config = {
    method: 'GET',
    url: 'firmwares'
  }

  return (dispatch) => {
    dispatch({ type: REQUEST_FILE })

    return axios(config)
      .then(response => {
        dispatch(fetchFilesSuccess(response.data));
      })
      .catch(error => {
        error.response.data.errors.map(err => {
          dispatch(addNotification("Error!", err, "error"));
        });   
      })
  }
}

export const uploadFile = (firmware) => {
  const config = {
    method: 'POST',
    url: 'upload',
    data: firmware
  }

  return (dispatch) => {
    return axios(config)
      .then(response => {
        dispatch(uploadFileSuccess(response.data));
        dispatch(addNotification("Success!", "The file was successfully uploaded!", "success"));
      })
      .catch(error => {
        error.response.data.errors.map(err => {
          dispatch(addNotification("Error!", err, "error"));
        });   
      });
  }
}

export const removeFile = (firmwareId) => {
  const config = {
    method: 'DELETE',
    url: `firmwares/${firmwareId}`
  }

  return (dispatch) => {
    return axios(config)
      .then(response => {
        dispatch(removeFileSuccess(firmwareId));
        dispatch(addNotification("Success!", "The file was successfully removed!", "success"));
      })
      .catch(error => {
        error.response.data.errors.map(err => {
          dispatch(addNotification("Error!", err, "error"));
        });   
      })
  }
}


export const uploadFileSuccess = (firmware) => {
  return {
    type: UPLOAD_FILE,
    firmware
  }
}

export const fetchFilesSuccess = (firmwares) => {
  return {
    type: FETCH_FILES,
    firmwares
  }
}

export const removeFileSuccess = (firmwareId) => {
  return {
    type: REMOVE_FILE,
    firmwareId
  }
}
