import { LOGIN_SUCCESS, LOG_OUT, RESET_PASSWORD_SUCCESS, FORGOT_PASSWORD_SUCCESS, RESET_USER_PASSWORD_SUCCESS } from './constants';
import { addNotification } from './notificationAction';
import { getUserToken } from '../utils/AuthService';
import { showModal } from './modalAction';
import axios from 'axios';
import history from '../containers/history';

const token = document.getElementsByName('csrf-token')[0].getAttribute('content');

axios.defaults.baseURL = '/api/';
axios.defaults.headers.common['X-CSRF-Token'] = token;
axios.defaults.headers.common['Accept'] = 'application/json';

export function loginUser(session) {
  const config = {
    method: 'POST',
    url: 'login',
    data: session
  }

  return (dispatch) => {
    return axios(config).then(response => {
      const { username, admin } = { ...response.data }
      localStorage.setItem('user', username);
      if(admin)
      {
        dispatch(loginSuccess());
        history.push('/');
      }
      else
      {
        dispatch(showModal('Admin Only', 'CREATE_ENTITY'));
      }
    }).catch(error => {
      error.response.data.errors.map(err => {
        dispatch(addNotification("Error!", err, "error"));
      }); 
    });
  }
}

export function logOutUser() {
  const config = {
    method: 'DELETE',
    url: 'logout',
    data: { token: getUserToken() }
  }

  const logoutAction = (dispatch) => {
    localStorage.removeItem('user');
    document.cookie = "jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; //On logout, the cookie is not being deleted properly on the client side. This should take care of it.
    dispatch(logOutSuccess());
    history.push('/login')
  };

  return (dispatch) => {
    return axios(config).then(
      response => logoutAction(dispatch)
    ).catch(error => {
      if (error.response.status === 401) {
        logoutAction(dispatch);
      }
      error.response.data.errors.map(err => {
        dispatch(addNotification("Error!", err, "error"));
      });   
    });
  }

}

export function resetPassword(credentials) {
  const config = {
    method: 'PUT',
    url: 'resetpassword',
    data: credentials
  }

  return (dispatch) => {
    return axios(config).then(response => {
      if(response.status == 200)
      {
        dispatch(addNotification("Success!","Your password was successfully reset!", "success"))
        dispatch(resetPasswordSuccess(response.data));
        setTimeout(function(){ history.push('/login'); }, 3000);
      }
      else
      {
        dispatch(addNotification("Error!", "An error occurred while resetting your password", "error"));
      }
    }).catch(error => {
      error.response.data.errors.map(err => {
        dispatch(addNotification("Error!", err, "error"));
      });   
    });
  }
}

export function forgotPassword(email) {
  const config = {
    method: 'POST',
    url: 'forgotpassword',
    data: email
  }

  return (dispatch) => {
    return axios(config).then(response => {
      if(response.status == 200)
      {
        dispatch(addNotification("Success!","An email has been sent with instructions to reset your password", "success"))
        dispatch(forgotPasswordSuccess());
        setTimeout(function(){ history.push('/login'); }, 3000);
      }
      else
      {
        dispatch(addNotification("Error!", "An error occurred during the forget password operation", "error"));
      }
    }).catch(error => {
      error.response.data.errors.map(err => {
        dispatch(addNotification("Error!", err, "error"));
      });   
    });
  }
}

export function resetUserPassword(email) {
  const config = {
    method: 'POST',
    url: 'forgotpassword',
    data: email
  }

  return (dispatch) => {
    return axios(config).then(response => {
      dispatch(resetUserPasswordSuccess());
      dispatch(addNotification("Success!", "An email has sent to the user with further instructions", "success"));
    }).catch(error => {
      error.response.data.errors.map(err => {
        dispatch(addNotification("Error!", err, "error"));
      });   
    });
  }
}

export function loginSuccess(user) {
  return {
    type: LOGIN_SUCCESS,
    user
  }
}

export function resetPasswordSuccess(user) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    user
  }
}

export function forgotPasswordSuccess() {
  return {
    type: FORGOT_PASSWORD_SUCCESS
  }
}

export function resetUserPasswordSuccess() {
  return {
    type: RESET_USER_PASSWORD_SUCCESS
  }
}

export function logOutSuccess() {
  return {
    type: LOG_OUT
  }
}
