import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { hideModal } from '../../actions/modalAction';
import { updateUser } from '../../actions/userAction';
import { updateClinic } from '../../actions/clinicAction';
import { updateAlterationCode } from '../../actions/alterationCodeAction';
import UserForm from '../forms/UserForm';
import ClinicForm from '../forms/ClinicForm';
import AlterationCodeForm from '../forms/AlterationCodeForm';

class UpdateModal extends Component {
  constructor(props){
    super(props);
  }

  renderForm() {
    switch (this.props.inputFormType) {
      case 'User':
        return <UserForm userData={this.props.data} handleSubmission={this.props.updateUser} />
      case 'Clinic':
        return <ClinicForm clinicData={this.props.data} handleSubmission={this.props.updateClinic} />
      case 'Alteration Code':
        return <AlterationCodeForm alterationCodeData={this.props.data} handleSubmission={this.props.updateAlterationCode} />
      default:
        return <div><h3>That form does not exist</h3></div>
    }
  }

  render() {
    return (
      <Modal show={this.props.showModal} onHide={this.props.hideModal}>
        <Modal.Header closeButton>
            <Modal.Title>Update {this.props.group}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { this.renderForm() }
        </Modal.Body>
      </Modal>
    )
  }
}

UpdateModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  updateClinic: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  updateAlterationCode: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  group: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
  const { modalProps } = {...state.modals};
  return { 
    data: modalProps.modalData.rowData,
    inputFormType: modalProps.modalData.group,
    group: modalProps.modalData.group,
    showModal: modalProps.showModal
  }
}

const mapDispatchToProps = dispatch => {
  return {
    hideModal: () => { dispatch(hideModal()) },
    updateUser: (user) => { dispatch(updateUser(user)) },
    updateClinic: (clinic) => { dispatch(updateClinic(clinic)) },
    updateAlterationCode: (alterationCode) => { dispatch(updateAlterationCode(alterationCode)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateModal);
