import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { showModal, hideModal } from '../../actions/modalAction';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Dropdown, MenuItem } from 'react-bootstrap';
//import 'react-datepicker/dist/react-datepicker.css';

class AlterationCodeForm extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      expires_at: this.props.alterationCodeData ? moment(this.props.alterationCodeData.expires_at) : moment(),
      disable_datepicker: false
    }
  }

  handleCodeChange = e => {
    this.setState({code: e.target.value})
  }

  handleCodeGeneration = e => {
    let code = document.getElementsByName("code")[0];
    code.value = this.generateRandomCode();
  }

  handleDateChange = date => {
    this.setState({ expires_at: date})
  }

  handleIndefiniteCheck = e => {
    this.setState({ disable_datepicker: e.target.checked });
  }

  generateRandomCode = () => {
    let chars = '23456789abcdefghjikmnpqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ';
    let result = '';
    for (var i = 0; i < 5; i++) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }

  resetAlterationCodeForm = () => {
    this.setState({
      startDate: moment(),
      disable_datepicker: false
    });
  }

  render() {
    let { id, code, expires_at, is_indefinite } = { ...this.props.alterationCodeData };
    return (
      <div>
        <form id="alteration-code-form" onSubmit={e => {
          e.preventDefault();

          let alterationCode = {
            id: id,
            expires_at: this.state.expires_at,
            code: code.value.replace(/\s/g,''),
            is_indefinite: is_indefinite.checked,
          }
          
          this.props.handleSubmission(alterationCode);

          e.target.reset();
          this.resetAlterationCodeForm();
        }} className="form-vertical">
          <div className="form-group">
            <div className="row">  
              <div className="col-md-6">
                <input type="text" name="code" placeholder="Enter or Generate a code" ref={node => code = node} defaultValue={code} className="form-control" required />
              </div>
              <div className="col-md-2">
                <button className="btn btn-primary" type="button" onClick={e => this.handleCodeGeneration(e)}><i className="fa fa-code" aria-hidden="true" />&nbsp;Generate Code</button>
              </div>
            </div><br />
            <div className="row">
              <div className="col-md-6">
                <label className="checkbox-inline">
                  <input type="checkbox" name="indefinite" ref={node => is_indefinite = node} defaultChecked={is_indefinite} onClick={e => this.handleIndefiniteCheck(e)}/>
                  &nbsp;Indefinite
                </label>
              </div>
              <div className="col-md-6">
                <DatePicker selected={this.state.expires_at} onChange={e => this.handleDateChange(e)} disabled={this.state.disable_datepicker} />
              </div>  
            </div><br />
            <div className="row text-center">
              <button className="btn btn-cancel" type="button" onClick={this.props.hideModal}>Cancel</button>&nbsp;&nbsp;
              <button className="btn btn-primary">Submit</button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    hideModal: () => { dispatch(hideModal()) }
  }
}

export default withRouter(connect(null, mapDispatchToProps)(AlterationCodeForm));
