import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { uploadFile } from '../../actions/firmwareAction';
import { showModal } from '../../actions/modalAction';
import { addNotification } from '../../actions/notificationAction';

class FirmwareForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      version: ''
    }
  }

  resetDropdownAndSignature = () => {
    this.setState({
      version: ''
    });
  }

  handleVersionChange = e => {
    this.setState({ version: e.target.value });
  }

  render() {
    let file = {};
    return (
    <div>
      <form id="firmware-form" encType="multipart/form-data" onSubmit={e => {
          e.preventDefault();

          if(file.files.length < 1 || !file.files[0].name.endsWith(".zip"))
          {
            this.props.addNotification("Error!", "Please upload a file with a .ZIP extension", "error");
            e.target.reset();
            this.resetDropdownAndSignature();
            return;
          }

          if(this.state.version === '' || this.state.version.length > 30)
          {
            this.props.addNotification("Error!", "Please enter a version number (30 characters max)", "error");
            e.target.reset();
            this.resetDropdownAndSignature();
            return;
          }

          let firmware = new FormData();
          firmware.append('attachment', file.files[0]);
          firmware.append('file_name', file.files[0].name);
          firmware.append('size', file.files[0].size);
          firmware.append('version', this.state.version);

          this.props.uploadFile(firmware);

          e.target.reset();
          this.resetDropdownAndSignature();

        }} className="form-horizontal">
        <div className="form-group">
            <div className="col-md-12 firmware-form-space">
              <input type="file" accept=".zip" name="file" ref={node => file = node} placeholder="File Upload" className="form-control file-upload" />
            </div>
            <div className="col-md-12 firmware-form-space">
              <input type="text" name="version" value={this.state.version} placeholder="Version" className="form-control version" onChange={e => this.handleVersionChange(e)}/>
            </div>
            <div className="col-md-12 firmware-form-space text-center">
              <button className="btn btn-primary" id="btnUpload"><i className="fa fa-upload" />&nbsp;Upload</button>
            </div>
        </div>
      </form>
    </div>
  )}
}

const mapStateToProps = state => {
  const { files, isLoading } = { ...state.files }
  return {
    files,
    isLoading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    uploadFile: (file) => { dispatch(uploadFile(file)) },
    showModal: (data, type) => { dispatch(showModal(data, type)) },
    addNotification: (title, message, alertType) => { dispatch(addNotification(title, message, alertType))}
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FirmwareForm));
