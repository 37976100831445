import { jwtDecode } from 'jwt-decode';
import { TOKEN_EXPIRED } from '../actions/constants';
import history from '../containers/history';

export function isLoggedIn(dispatch) {
  const token = getToken();
  return !!token && !isTokenExpired(token)
}

export function getUserToken(name = 'jwt') {
  name += '=';
  for (let ca = document.cookie.split(/;\s*/), i = ca.length - 1; i >= 0; i--)
    if (!ca[i].indexOf(name))
      return ca[i].replace(name, '');
}

function getToken() {
  return getUserToken();
}

function isTokenExpired(token) {
  const expiration = getExpirationDate(token);
  return expiration < Date.now();
}

function getExpirationDate(idToken) {
  const token = jwtDecode(idToken);
  if (!token.expires) { return null; }

  return new Date(0).setUTCSeconds(token.expires)
}

export function checkToken(store) {
  let userToken = getToken(); 
  let isExpired = isTokenExpired(userToken);
  if (isExpired) {
    store.dispatch({
      type: TOKEN_EXPIRED,
      payload: {userToken}
    });
    history.push('/login');
  }
}
