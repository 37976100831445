export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export function showModal(data, type) {
    return {
        type: SHOW_MODAL,
        modalType: type,
        modalProps: {showModal: true, modalData: data}
    }
}

export function hideModal() {
    return {
        type: HIDE_MODAL,
        modalProps: { showModal: false, modalData: {} }
    }
}