import { UPDATE_USER, CREATE_USER, REMOVE_USER, REQUEST_USER, FETCH_USERS, REQUEST_FAILURE } from '../actions/constants';

const initialState = {
    users: {},
    isLoading: true
}

export default function userReducer(state = initialState, action) {
    switch(action.type){
        case REQUEST_USER:
            return {
                ...state,
                isLoading: true,
                users: {}
            };
        case FETCH_USERS:
            return {
                ...state, 
                isLoading: false, 
                ...action.users
            };
        case CREATE_USER:
            return {
                ...state, 
                isLoading: false, 
                users:[...state.users, Object.assign({}, {...action.user.user})].sort((a, b) => {
                    return a['last_name'].localeCompare(b['last_name']);      
                })
            };
        case UPDATE_USER:

            const updatedUser = state.users.map( (user, index) => {
                if(user.id !== action.user.user.id) {
                    return user;
                }
            
                return {
                    ...user,
                    ...action.user.user
                };    
            });
            return {
                ...state, 
                isLoading: false, 
                users: [...updatedUser]
            };
        case REMOVE_USER:
            return { 
                ...state, 
                isLoading: false, 
                users: [...state.users.filter((user) => { return user.id !== action.userId})] 
            };
        default:
            return state
    }
}