import { SHOW_MODAL, HIDE_MODAL } from '../actions/modalAction';

const initialState = {
    modalType: null,
    modalProps: {}
}

export default function modalReducer(state = initialState, action) {
    switch(action.type) {
        case SHOW_MODAL:
            return {
                modalType: action.modalType,
                modalProps: action.modalProps
            };
        case HIDE_MODAL:
            return {
                modalType: action.modalType,
                modalProps: action.modalProps
            };
        default:
            return state;
    }
}