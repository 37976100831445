import { FETCH_ELBOW_VERSIONS, REQUEST_ELBOW_VERSION } from '../actions/constants';
import { addNotification } from './notificationAction';
import { getUserToken } from '../utils/AuthService';
import axios from 'axios';

const token = document.querySelector("meta[name=csrf-token]").content;
const bearer = getUserToken();

axios.defaults.baseURL = '/api/';
axios.defaults.headers.common['X-CSRF-Token'] = token;
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Authorization'] = `Bearer ${bearer}`;

export const requestElbowVersions = () => {
  const config = {
    method: 'GET',
    url: 'elbow_versions'
  }

  return (dispatch) => {
    dispatch({ type: REQUEST_ELBOW_VERSION })

    return axios(config)
      .then(response => {
        dispatch(requestElbowVersionSuccess(response.data));
      })
      .catch(error => {
        dispatch(addNotification("Error!", error.message, "error"));
      })
  }
}

export const requestElbowVersionSuccess = (elbow_versions) => {
  return {
    type: FETCH_ELBOW_VERSIONS,
    elbow_versions
  }
}
