import React, { Component } from 'react';
import NotificationSystem from 'react-notification-system';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class Notification extends Component {
  constructor(props) {
    super(props);
  }
  
  componentDidMount() {
    this.notificationSystem = this.refs.notificationSystem;
  }
    
  componentWillReceiveProps(newProps) {
    if (Object.keys(newProps.notification).length > 0) {
      const { title, message, level } = newProps.notification;
      this.notificationSystem.addNotification({
        title,
        message,
        level
      });
    }
  }
    
  render() {
    return (
      <NotificationSystem ref="notificationSystem" />
    );
  }
}
     
const mapStateToProps = (state) => {
  return {
    notification: state.notifications
  };
}

export default withRouter(connect(mapStateToProps, null)(Notification));
