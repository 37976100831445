import { createStore, applyMiddleware, compose } from 'redux';
import { checkToken } from '../utils/AuthService';
import thunk from 'redux-thunk';
import rootReducer from '../reducers/index';

const expiredTokenMiddleware = store => {
    setInterval(checkToken.bind(null, store), 100 * 100 * 90); //15 minutes
    return (next) => (action) => next(action);
}

const middlewares = [thunk, expiredTokenMiddleware];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION__COMPOSE || compose;

const configureStore = preloadedState => {
    const store = createStore(
        rootReducer,
        preloadedState,
        composeEnhancers(applyMiddleware(...middlewares))
    )

    if(module.hot){
        module.hot.accept('../reducers', () => {
            const nextRootReducer = require('../reducers').default
            store.replaceReducer(nextRootReducer)
        })
    }

    return store
}

export default configureStore;