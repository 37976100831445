import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { requestElbowVersions } from '../actions/elbowVersionAction';
import ReactTable from "react-table";
import moment from 'moment';

class ElbowVersionAdmin extends Component {

  constructor(props){
    super(props);
  }

  renderLoading = () => {
    return (
      <div className="text-center">
        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
      </div>
    )
  }

  componentDidMount() {
      this.props.requestElbowVersions();
  }

  renderNoVersionsLoaded = () => {
    return (
      <div className="panel-body text-center">
        <span>No Versions Loaded at this time</span>
      </div>
    )
  }

  renderTable = (data) => {
    return (
      <ReactTable
          data={data}
          noDataText="No matches found with that elbow serial number"
          columns={[
            {
              Header: "Serial Number",
              id: "serial_number",
              accessor: d => d.serial_number,
              filterable: true,
              headerClassName: 'header',
            },
            {
              Header: "Version",
              id: "version",
              accessor: d => d.version,
              filterable: true,
              headerClassName: 'header',
            },
            {
              Header: "Reported At",
              id: "updated_at",
              accessor: d => moment(d.updated_at).format('lll'),
              filterable: false,
              headerClassName: 'header',
            }
          ]}
          defaultPageSize={15}
          showPageSizeOptions={false}
          getNoDataProps={() => {return {
            style: { backgroundColor: '#f7f7f7', fontWeight: 'bold'}
          }}}
          className="-striped -highlight"
        />
    )
}

render() {
    return (
      <div className="panel-body">
        {this.props.isLoading ? this.renderLoading() : Object.keys(this.props.elbow_versions).length > 0 ? this.renderTable(this.props.elbow_versions) : this.renderNoVersionsLoaded() }
      </div>
    )
}
}

ElbowVersionAdmin.propTypes = {
  requestElbowVersions: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
  const { elbow_versions, isLoading } =  {...state.elbow_versions}
  return {
    elbow_versions,
    isLoading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    requestElbowVersions: () => { dispatch(requestElbowVersions()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ElbowVersionAdmin);
