import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { createUser } from '../../actions/userAction';
import { createClinic } from '../../actions/clinicAction';
import { createAlterationCode } from '../../actions/alterationCodeAction';
import { hideModal } from '../../actions/modalAction';
import UserForm from '../forms/UserForm';
import ClinicForm from '../forms/ClinicForm';
import FirmwareForm from '../forms/FirmwareForm';
import AlterationCodeForm from '../forms/AlterationCodeForm';
import AdminOnlyForm from '../forms/AdminOnlyForm';

class CreateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
          title: ''
        }
    }

    componentDidMount()
    {
      switch(this.props.inputFormType)
        {
          case 'User':
            this.setState({title: `Create ${this.props.inputFormType}`});
            break;
          case 'Clinic':
            this.setState({title: `Create ${this.props.inputFormType}`});
            break;
          case 'Firmware':
            this.setState({title: `Upload ${this.props.inputFormType}`});
            break;
          case 'Alteration Code':
            this.setState({title: `Create ${this.props.inputFormType}`});
            break;
          case 'Admin Only':
            this.setState({title: "Attention!"});
            break;
          default:
            this.setState({title: "Not Found!"});
        }
    }
  
    renderForm() {
      switch (this.props.inputFormType) {
        case 'User':
          return <UserForm handleSubmission={this.props.createUser} />
        case 'Clinic':
          return <ClinicForm handleSubmission={this.props.createClinic} />
        case 'Firmware':
          return <FirmwareForm />
        case 'Alteration Code':
          return <AlterationCodeForm handleSubmission={this.props.createAlterationCode} />
        case 'Admin Only':
          return <AdminOnlyForm />
        default:
          return <div><h3>That form does not exist</h3></div>  
      }
    }

    render() {
        return (
            <Modal show={this.props.showModal} onHide={this.props.hideModal}>
              <Modal.Header closeButton>
              <Modal.Title>{this.state.title}</Modal.Title>
                  </Modal.Header>
              <Modal.Body>
                { this.renderForm() }
              </Modal.Body>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    const { modalProps } = {...state.modals };
    return { 
      inputFormType: modalProps.modalData,
      showModal: modalProps.showModal
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      hideModal: () => { dispatch(hideModal()) },
      createUser: (user) => { dispatch(createUser(user)) },
      createClinic: (clinic) => { dispatch(createClinic(clinic)) },
      createAlterationCode: (alterationCode) => {dispatch(createAlterationCode(alterationCode)) },
      showForm: (data, type) => { dispatch(showForm(data, type))}
    }
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(CreateModal);
