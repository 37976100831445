import { LOGIN_SUCCESS, LOG_OUT, RESET_PASSWORD_SUCCESS, FORGOT_PASSWORD_SUCCESS, TOKEN_EXPIRED } from '../actions/constants';
import { getUserToken } from '../utils/AuthService';

const token = getUserToken();

export default function sessionReducer(state = { session: !!token }, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
    case LOG_OUT:
    case FORGOT_PASSWORD_SUCCESS:  
    case TOKEN_EXPIRED: 
      return !!token
    default:
      return state;
  }
}