import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import UserAdmin from './UserAdmin';
import ClinicAdmin from './ClinicAdmin';
import AlterationCodeAdmin from './AlterationCodeAdmin';
import FirmwareAdmin from './FirmwareAdmin';
import ElbowVersionAdmin from './ElbowVersionAdmin';
import { Tabs, Tab, PageHeader } from 'react-bootstrap';
import { logOutUser } from '../actions/loginAction';
import 'promise-polyfill/src/polyfill';
require('es6-object-assign').polyfill();

class Main extends Component {
  constructor(props){
    super(props);

    this.state = {
      user: null
    };
  }

  componentDidMount() {
    this.setState({user: localStorage.getItem('user')});
  }

  handleLogout = e => {
    this.props.logOutUser();
  }

  render() {
    return (
      <div className="container">
        <h5 className="pull-right">
          Welcome, {this.state.user}! &nbsp;
          <button className="btn btn-default btn-sm" onClick={e => this.handleLogout(e)}>Logout</button>
        </h5>
        <br /><br />
        <Tabs defaultActiveKey={1} id="clinic-tabs">
          <Tab eventKey={1} title="User Admin">
            <br />
            <UserAdmin />
          </Tab>
          <Tab eventKey={2} title="Clinic Admin">
            <br />
            <ClinicAdmin />
          </Tab>
          <Tab eventKey={3} title="Alteration Code Admin">
            <br />
            <AlterationCodeAdmin />
          </Tab>
          <Tab eventKey={4} title="Firmware Admin">
            <br />
            <FirmwareAdmin />
          </Tab>
          <Tab eventKey={5} title="Version Tracking">
            <br />
            <ElbowVersionAdmin />
          </Tab>
        </Tabs>
      </div>
    )
  }
}


Main.propTypes = {
  logOutUser: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => {
  return {
    logOutUser: () => { dispatch(logOutUser()) }
  }
}

export default withRouter(connect(null, mapDispatchToProps)(Main));
