import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideModal } from '../../actions/modalAction';
import { logOutUser } from '../../actions/loginAction';


class AdminOnlyForm extends Component {
  constructor(props) {
    super(props);
  }

  handleCancelClick = e => {
    this.props.hideModal();
    this.props.logOutUser();
  }

  render() {              
    return (
      <form id="admin-only" className="form-vertical text-center" >
        <div className="row">
          <h4>Admins Only</h4>
        </div>
        <div className="row">
          <div>This site is for admins only.</div>
        </div>
          <div className="row">
              <div>If there has been a mistake, please call Steeper Group Support at the following number:</div>
          </div>
          <div className="row">
              <div>For USA & Canada contact: (+1) 210 481 4126</div>
          </div>
          <div className="row">
              <div>International contact: +44 (0) 870 240 4133</div>
          </div>
        <br/>
        <div>
          <button className="btn btn-cancel" type="button" onClick={e => this.handleCancelClick(e)}>OK</button>
        </div>
      </form>
    )
  }
}

AdminOnlyForm.propTypes = {
  hideModal: PropTypes.func.isRequired,
  logOutUser: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => {
  return {
    hideModal: () => { dispatch(hideModal()) },
    logOutUser: () => { dispatch(logOutUser()) }
  }
}

export default connect(null, mapDispatchToProps)(AdminOnlyForm);