import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Panel, OverlayTrigger, Tooltip, FormControl } from "react-bootstrap";
import { resetPassword } from "../../actions/loginAction";

const passwordTooltip = (
  <Tooltip id="passwordTooltip">Password does not meet requirements</Tooltip>
);

const noMatchTooltip = (
  <Tooltip id="noMatchTooltip">Passwords don't match!</Tooltip>
);

const requirementsStyle = {
  background: "#d9edf7",
  padding: "5px",
  border: "1px solid #668fa3",
  borderRadius: "5px",
  marginBottom: "20px"
};
class PasswordReset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      confirm_password: "",
      token: props.match.params.token
    };
  }

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleOnBlur = e => {
    this.validateForm(e.target.name);
  };

  checkIfPasswordsMatch() {
    return this.refs["password"].value === this.refs["confirm_password"].value;
  }

  validateForm = refName => {
    const validity = this.refs[refName].validity;
    const hasPassword = refName === "password";
    const hasPasswordConfirm = refName === "confirm_password";

    if (!validity.valid) {
      if (validity.valueMissing) {
        hasPassword
          ? this.refs.passwordOverlay.show()
          : this.refs.confirmPasswordOverlay.show();
      } else if (hasPassword && validity.patternMismatch) {
        this.refs.passwordOverlay.show();
      }
      return false;
    }

    if (!this.checkIfPasswordsMatch() && this.refs["confirm_password"].value != "") {
      this.refs.confirmPasswordOverlay.show();
      return false;
    }

    if (hasPassword) {
      this.refs.passwordOverlay.hide();
    }
    this.refs.confirmPasswordOverlay.hide();
    document.getElementById("btnPassword").disabled = false;
    return true;
  };

  render() {
    return (
      <Panel header="Update Password" bsStyle="info" className="login">
        <form
          id="password-reset"
          onSubmit={e => {
            e.preventDefault();

            if (!this.checkIfPasswordsMatch()){
              return;
            }

            this.props.resetPassword(this.state);

            e.target.reset();
          }}
        >
          <p style={requirementsStyle}>
            Passwords need to be 8 characters with at least one character from
            three of the four categories: Uppercase, Lowercase, Number, and
            Special Character
          </p>
          <OverlayTrigger
            trigger={null}
            ref="passwordOverlay"
            placement="right"
            overlay={passwordTooltip}
          >
            <input
              type="password"
              className="form-control"
              name="password"
              ref="password"
              placeholder="New Password"
              pattern="^(?=.{8,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*$"
              value={this.state.password}
              onChange={e => this.handleInputChange(e)}
              onBlur={e => this.handleOnBlur(e)}
              required
            />
          </OverlayTrigger>
          <br />
          <OverlayTrigger
            trigger={null}
            ref="confirmPasswordOverlay"
            placement="right"
            overlay={noMatchTooltip}
          >
            <input
              type="password"
              className="form-control"
              name="confirm_password"
              ref="confirm_password"
              placeholder="Confirm Password"
              value={this.state.confirm_password}
              onChange={e => this.handleInputChange(e)}
              onBlur={e => this.handleOnBlur(e)}
              required
            />
          </OverlayTrigger>
          <br />
          <button className="btn btn-primary" id="btnPassword" disabled>
            Update Password
          </button>
        </form>
      </Panel>
    );
  }
}

PasswordReset.propTypes = {
  resetPassword: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    resetPassword: credentials => {
      return dispatch(resetPassword(credentials));
    }
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(PasswordReset)
);
