import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { requestClinics } from '../../actions/clinicAction';
import { resetUserPassword } from '../../actions/loginAction';
import { hideModal } from '../../actions/modalAction';
import { Dropdown, MenuItem } from 'react-bootstrap';

class UserForm extends Component {

  constructor(props) {
      super(props);

      const clinic = !this.props.userData ? null : this.props.clinics.filter(clinic => {
        if(this.props.userData.clinic_id == clinic.id) {
          return clinic;
        }
      })[0];
      const dropdownTitle = clinic ? clinic.name : '--- Please Select a Clinic ---';

      this.state = {
        title: dropdownTitle,
        clinicId: clinic ? clinic.id : -1
      }

   }

  handleClinicChange = e => {
    this.props.clinics.forEach((c) => {
      if (c.id == e) {
        this.setState({ title: c.name, clinicId: c.id });
      }
    })
  }

  handleResetPasswordRequest = e => {
    const email = document.getElementById("user-email").value;
    this.props.resetUserPassword({"email": email});
    this.props.hideModal();
  }

  render() {
      let { id, first_name, last_name, email, clinic_id, location, admin } = {...this.props.userData};
      return (
        <form id="user-form" onSubmit={e => {
          e.preventDefault();
          var user = {
            id,
            first_name: first_name.value,
            last_name: last_name.value,
            email: email.value,
            clinic_id: this.state.clinicId,
            location: location.value,
            admin: admin.checked
          };
          this.props.handleSubmission(user);
          e.target.reset();
          this.props.hideModal();
        }} className="form-vertical" >
          <div className="row">
            <div className="col-md-6">
              <input type="text" name="first_name" placeholder="First Name" ref={node => first_name = node}
               defaultValue={first_name} className="form-control" required />
            </div>
            <div className="col-md-6">
              <input type="text" name="last_name" placeholder="Last Name" ref={node => last_name = node}
               defaultValue={last_name} className="form-control" required />
            </div>
          </div>
          <br/>
          <div className="row">
            <div className="col-md-6">
              <input type="text" id="user-email" name="email" placeholder="Email" ref={node => email = node}
               defaultValue={email} className="form-control" required />
            </div>
            <div className="col-md-6">
              {this.props.isLoading ? null :
                <Dropdown id="user-clinic-dropdown" onSelect={(e) => this.handleClinicChange(e)}>
                  <Dropdown.Toggle>
                    {this.state.title}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {this.props.clinics.map((clinic, i) => {
                      return <MenuItem eventKey={clinic.id} key={clinic.id} value={i}>{clinic.name}</MenuItem>
                    })}
                  </Dropdown.Menu>
                </Dropdown >
              }
            </div>
          </div>
          <br/>
          <div className="row">
            <div className="col-md-6">
              <input type="text" name="location" placeholder="Location" ref={node => location = node} defaultValue={location} className="form-control" />
            </div>
            <div className="col-md-6">
              <label className="checkbox-inline">
                <input type="checkbox" name="admin" placeholder="Admin" ref={node => admin = node} defaultChecked={admin} />
                Is Admin?
              </label>
            </div>
          </div>
          <br />
          <div className="row text-center">
            <button className="btn btn-default" type="button" onClick={(e) => this.handleResetPasswordRequest(e)}
             disabled={!this.props.userData}>Reset Password</button>
          </div>
          <br />
          <div className="text-center">
            <button className="btn btn-cancel" type="button" onClick={this.props.hideModal}>Cancel</button>&nbsp;&nbsp;
            <button className="btn btn-primary">Submit</button>
          </div>
        </form>
      )
  }
}

UserForm.propTypes = {
  hideModal: PropTypes.func.isRequired,
  requestClinics: PropTypes.func.isRequired,
  resetUserPassword: PropTypes.func.isRequired,
  clinics: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  userData: PropTypes.object
}

const mapStateToProps = state => {
  const { clinics, isLoading } = { ...state.clinics }
  return {
    clinics,
    isLoading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    hideModal: () => { dispatch(hideModal()) },
    requestClinics: () => { dispatch(requestClinics()) },
    resetUserPassword: (email) => { dispatch(resetUserPassword(email)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
