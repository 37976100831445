import { CREATE_CLINIC, UPDATE_CLINIC, REMOVE_CLINIC, FETCH_CLINICS, REQUEST_CLINIC, ADD_NOTIFICATION } from '../actions/constants';
import { addNotification } from './notificationAction';
import { getUserToken } from '../utils/AuthService';
import axios from 'axios';

const token = document.getElementsByName('csrf-token')[0].getAttribute('content');
const bearer = getUserToken();

axios.defaults.baseURL = '/api/';
axios.defaults.headers.common['X-CSRF-Token'] = token;
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Authorization'] = `Bearer ${bearer}`;

export const requestClinics = () => {
    const config = {
        method: 'GET',
        url: 'clinics'
    }

    return (dispatch) => {
        dispatch({ type: REQUEST_CLINIC })

        return axios(config)
            .then(response => {
                dispatch(requestClinicSuccess(response.data));
            })
            .catch(error => {
                error.response.data.errors.map(err => {
                    dispatch(addNotification("Error!", err, "error"));
                });   
            });
    }
}

export const createClinic = (clinic) => {
    const config = {
        method: 'POST',
        url: 'clinics',
        data: { clinic: clinic }
    }
    return (dispatch) => {
      return axios(config)
        .then(response => {
          dispatch(createClinicSuccess(response.data))
          dispatch(addNotification("Success!", "Clinic was successfully created!", "success"));
        })
        .catch(error => {
            error.response.data.errors.map(err => {
                dispatch(addNotification("Error!", err, "error"));
            });            
        });
    };
  };

export const updateClinic = (clinic) => {
    const config = {
        method: 'PUT',
        url: `clinics/${clinic.id}`,
        data: { clinic: clinic }
    }
    return (dispatch) => {
      return axios(config)
        .then(response => {
          dispatch(updateClinicSuccess(response.data));
          dispatch(addNotification("Success!", "Clinic was successfully updated!", "success"));
        })
        .catch(error => {
            error.response.data.errors.map(err => {
                dispatch(addNotification("Error!", err, "error"));
            });
        });
    };
  };

export const removeClinic = (clinicId) => {
    const config = {
        method: 'DELETE',
        url: `clinics/${clinicId}`
    }
    return (dispatch) => {
      return axios(config)
        .then(response => {
          dispatch(removeClinicSuccess(clinicId));
          dispatch(addNotification("Success!", "Clinic was successfully removed!", "success"));
        })
        .catch(error => {
            error.response.data.errors.map(err => {
                dispatch(addNotification("Error!", err, "error"));
            });
        });
    };
  };

export const requestClinicSuccess = (clinics) => {
    return {
        type: FETCH_CLINICS,
        clinics
    }
}

export const createClinicSuccess = (clinic) => {
    return {
        type: CREATE_CLINIC,
        clinic
    }
}

export const updateClinicSuccess = (clinic) => {
    return {
        type: UPDATE_CLINIC,
        clinic
    }
}

export const removeClinicSuccess = (clinicId) => {
    return {
        type: REMOVE_CLINIC,
        clinicId
    }
}
