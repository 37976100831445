import { CREATE_USER, UPDATE_USER, REMOVE_USER, FETCH_USERS, REQUEST_USER, REQUEST_FAILURE, ADD_NOTIFICATION } from '../actions/constants';
import { addNotification } from './notificationAction';
import { getUserToken } from '../utils/AuthService';
import axios from 'axios';

const token = document.getElementsByName('csrf-token')[0].getAttribute('content');

const bearer = getUserToken();


axios.defaults.baseURL = '/api/';
axios.defaults.headers.common['X-CSRF-Token'] = token;
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Authorization'] = `Bearer ${bearer}`;

export const requestUsers = () => {
    const config = {
        method: 'GET',
        url: 'users'
    };

    return (dispatch) => {
        dispatch({ type: REQUEST_USER })

        return axios(config)
            .then(response => {
                dispatch(requestUserSuccess(response.data));
            })
            .catch(error => {
                dispatch(addNotification("Error!", error.response.data.errors[0], "error"))
            });
    }
}

export const createUser = (user) => {
    const config = {
        method: 'POST',
        url: 'users',
        data: { user: user }
    }
    return (dispatch) => {
      return axios(config)
        .then(response => {
          dispatch(createUserSuccess(response.data))
          dispatch(addNotification("Success!", "User was successfully created!", "success"));
        })
        .catch(error => {
            dispatch(addNotification("Error!", error.response.data.errors[0], "error"));
        });
    };
  };

export const updateUser = (user) => {
    const config = {
        method: 'PUT',
        url: `users/${user.id}`,
        data: { user: user }
    }
    return (dispatch) => {
      return axios(config)
        .then(response => {
          dispatch(updateUserSuccess(response.data));
          dispatch(addNotification("Success!", "User was successfully updated!", "success"));
        })
        .catch(error => {
            dispatch(addNotification("Error!", error.response.data.errors[0], "error"));
        });
    };
  };

export const removeUser = (userId) => {
    const config = {
        method: 'DELETE',
        url: `users/${userId}`
    }
    return (dispatch) => {
      return axios(config)
        .then(response => {
          dispatch(removeUserSuccess(userId));
          dispatch(addNotification("Success!", "User was successfully removed!", "success"));
        })
        .catch(error => {
            dispatch(addNotification("Error!", error.response.data.errors[0], "error"));
        });
    };
  };

export const requestUserSuccess = (users) => {
    return {
        type: FETCH_USERS,
        users
    }
}

export const createUserSuccess = (user) => {
    return {
        type: CREATE_USER,
        user
    }
}

export const updateUserSuccess = (user) => {
    return {
        type: UPDATE_USER,
        user
    }
}

export const removeUserSuccess = (userId) => {
    return {
        type: REMOVE_USER,
        userId
    }
}
