import { CREATE_ALTERATION_CODE, UPDATE_ALTERATION_CODE, REMOVE_ALTERATION_CODE, FETCH_ALTERATION_CODES, REQUEST_ALTERATION_CODE, ADD_NOTIFICATION } from '../actions/constants';
import { addNotification } from './notificationAction';
import { getUserToken } from '../utils/AuthService';
import axios from 'axios';

const token = document.querySelector("meta[name=csrf-token]").content;
const bearer = getUserToken();

axios.defaults.baseURL = '/api/';
axios.defaults.headers.common['X-CSRF-Token'] = token;
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Authorization'] = `Bearer ${bearer}`;

export const requestAlterationCodes = () => {
  const config = {
    method: 'GET',
    url: 'alteration_codes'
  }

  return (dispatch) => {
    dispatch({ type: REQUEST_ALTERATION_CODE })

    return axios(config)
      .then(response => {
        dispatch(requestAlterationCodeSuccess(response.data));
      })
      .catch(error => {
          error.response.data.errors.map(err => {
            dispatch(addNotification("Error!", err, "error"));
        });   
      });
  }
}

export const createAlterationCode = (alterationCode) => {
  const config = {
    method: 'POST',
    url: 'alteration_codes',
    data: { alteration_code: alterationCode }
  }
  return (dispatch) => {
    return axios(config)
      .then(response => {
        dispatch(createAlterationCodeSuccess(response.data))
        dispatch(addNotification("Success!", "AlterationCode was successfully created!", "success"));
      })
      .catch(error => {
          error.response.data.errors.map(err => {
            dispatch(addNotification("Error!", err, "error"));
        });   
      });
  };
};

export const updateAlterationCode = (alterationCode) => {
  const config = {
    method: 'PUT',
    url: `alteration_codes/${alterationCode.id}`,
    data: { alteration_code: alterationCode }
  }
  return (dispatch) => {
    return axios(config)
      .then(response => {
        dispatch(updateAlterationCodeSuccess(response.data));
        dispatch(addNotification("Success!", " The alteration code was successfully updated!", "success"));
      })
      .catch(error => {
          error.response.data.errors.map(err => {
            dispatch(addNotification("Error!", err, "error"));
        });   
      });
  };
};

export const removeAlterationCode = (alterationCodeId) => {
  const config = {
    method: 'DELETE',
    url: `alteration_codes/${alterationCodeId}`
  }
  return (dispatch) => {
    return axios(config)
      .then(response => {
        dispatch(removeAlterationCodeSuccess(alterationCodeId));
        dispatch(addNotification("Success!", "The alteration code was successfully removed!", "success"));
      })
      .catch(error => {
        error.response.data.errors.map(err => {
          dispatch(addNotification("Error!", err, "error"));
        });   
      });
  };
};

export const requestAlterationCodeSuccess = (alterationCodes) => {
  return {
    type: FETCH_ALTERATION_CODES,
    alterationCodes
  }
}

export const createAlterationCodeSuccess = (alterationCode) => {
  return {
    type: CREATE_ALTERATION_CODE,
    alterationCode
  }
}

export const updateAlterationCodeSuccess = (alterationCode) => {
  return {
    type: UPDATE_ALTERATION_CODE,
    alterationCode
  }
}

export const removeAlterationCodeSuccess = (alterationCodeId) => {
  return {
    type: REMOVE_ALTERATION_CODE,
    alterationCodeId
  }
}
