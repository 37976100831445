import { combineReducers } from 'redux';
import UserReducer from './userReducer';
import ModalReducer from './modalReducer';
import ClinicReducer from './clinicReducer';
import NotificationReducer from './notificationReducer';
import SessionReducer from './sessionReducer';
import FirmwareReducer from './firmwareReducer';
import AlterationCodeReducer from './alterationCodeReducer';
import ElbowVersionReducer from './elbowVersionReducer';

const rootReducer = combineReducers({
    users: UserReducer, 
    modals: ModalReducer,
    clinics: ClinicReducer,
    notifications: NotificationReducer,
    session: SessionReducer,
    firmwares: FirmwareReducer,
    alteration_codes: AlterationCodeReducer,
    elbow_versions: ElbowVersionReducer
});

export default rootReducer; 