import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { requestUsers, createUser } from '../actions/userAction';
import UserForm from './forms/UserForm';
import ReactTable from "react-table";
import { showModal, hideModal } from '../actions/modalAction';

class UserAdmin extends Component {
  constructor(props){
    super(props);
  }

  componentDidMount() {
    this.props.requestUsers();
  }

  filterNames = (filter, row) =>
  {
    var row_value = row[filter.id];
    if (typeof row_value != 'string')
      return false;
    return row_value.toLowerCase().includes(filter.value.toLowerCase());
  }

  renderLoading = () => {
    return (
      <div className="text-center">
        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
      </div>
    )
  }

  renderTable = (data) => {
    return (
      <div>
        <button className="btn btn-primary pull-right" type="button" onClick={() => this.props.showModal('User', 'CREATE_ENTITY')}><i className="fa fa-plus icon" />&nbsp;Add New User</button>
        <br />
        <br />
        <ReactTable
            data={data}
            noDataText="No matching users found"
            columns={[
              {
                Header: "User Name",
                id: "reverse_name",
                accessor: d => d.reverse_name,
                filterable: true,
                headerClassName: 'header',
                filterMethod: (filter, row) => {
                    return this.filterNames(filter, row)
                  },
                Cell: row => (
                  <div className="data-row" onClick={(data, type) => this.props.showModal({ rowData: row.original, group:'User'}, 'UPDATE_ENTITY')}>
                    {row.original.reverse_name}
                  </div>
                )
              },
              {
                Header: "Location",
                id: "location",
                accessor: d => d.location,
                filterable: true,
                filterMethod: (filter, row) => {
                  return this.filterNames(filter, row)
                },
                headerClassName: 'header'
              },
              {
                Header: "Clinic",
                id: "clinic",
                accessor: d => d.clinic_name,
                filterable: true,
                filterMethod: (filter, row) => {
                  return this.filterNames(filter, row)
                },
                headerClassName: 'header'
              },
              {
                Header: "Admin?",
                id: "admin",
                accessor: d => d.admin,
                filterable: true,
                headerClassName: 'header',
                Cell: ({ value }) => (value ? 'Yes' : ''),
                filterMethod: (filter, row) => {
                    if (filter.value === 'all') {
                      return true;
                    }
                    if (filter.value === "true") {
                      return row[filter.id] == true;
                    }
                    return row[filter.id] == false;
                },
                Filter: ({ filter, onChange }) =>
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: "100%", height: '100%' }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="all">Show All</option>
                      <option value="true">Admin Only</option>
                      <option value="false">Non-Admins</option>
                    </select>
              },
              {
                maxWidth: 20,
                headerClassName: 'header',
                Cell: row => (
                  <div className="pull-right">
                    <i className="fa fa-times fa-lg icon pull-right" onClick={(data, type) => this.props.showModal({ id: row.original.id, name: row.original.full_name, group: 'User' }, 'REMOVE_ENTITY')} />
                  </div>
                )
              }
            ]}
            defaultPageSize={15}
            showPageSizeOptions={false}
            getNoDataProps={() => {return {
              style: { backgroundColor: '#f0f0f0', fontWeight: 'bold'}
            }}}
            className="-striped -highlight"
          />
        </div>
    )
  }

  render() {
    return (
      <div>
        { this.props.isLoading ? this.renderLoading() : Object.keys(this.props.users).length > 0 ? this.renderTable(this.props.users) : <UserForm handleSubmission={this.props.createUser} /> }
      </div>
    )
  }
}

UserAdmin.propTypes = {
  requestUsers: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  const { users, isLoading } = { ...state.users }
  return {
    users,
    isLoading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    requestUsers: () => { dispatch(requestUsers())},
    createUser: (user) => { dispatch(createUser(user)) },
    showModal: (data, type) => { dispatch(showModal(data, type)) },
    hideModal: () => { dispatch(hideModal()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAdmin);
