import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideModal } from '../../actions/modalAction';


class ClinicForm extends Component {
  constructor(props) {
      super(props);
  }

  render() {
    let {id, name, address_1, address_2, city, state, zip, cpi_code, phone} = {...this.props.clinicData};
    
    return (
      <form id="clinic-form" onSubmit={e => {
        e.preventDefault();

        var clinic = {
          id,
          name: name.value,
          address_1: address_1.value,
          address_2: address_2.value,
          city: city.value,
          state: state.value,
          zip: zip.value,
          cpi_code: cpi_code.value,
          phone: phone.value,
        };

        this.props.handleSubmission(clinic);

        e.target.reset();

        this.props.hideModal();
      }} className="form-vertical">
        <div className="row">
          <div className="col-md-6">
            <input type="text" name="name" placeholder="Clinic Name" ref={node => name = node} defaultValue={name} className="form-control" required />
          </div>
          <div className="col-md-6">
            <input type="text" name="address_1" placeholder="Street Address" ref={node => address_1 = node} defaultValue={address_1} className="form-control" />
          </div>
        </div>
        <br/>
        <div className="row">
          <div className="col-md-6">
            <input type="text" name="address_2" placeholder="Suite Address" ref={node => address_2 = node} defaultValue={address_2} className="form-control" />
          </div>
          <div className="col-md-6">
            <input type="text" name="city" placeholder="City" ref={node => city = node} defaultValue={city} className="form-control" />
          </div>
        </div>
        <br/>
        <div className="row">
          <div className="col-md-6">
            <input type="text" name="state" placeholder="State" ref={node => state = node} defaultValue={state} className="form-control" />
          </div>
          <div className="col-md-6">
            <input type="text" name="zip" placeholder="Zip Code" ref={node => zip = node} defaultValue={zip} className="form-control" />
          </div>
        </div>
        <br/>
        <div className="row">
          <div className="col-md-6">
            <input type="text" name="cpi_code" placeholder="Customer Ref" ref={node => cpi_code = node} defaultValue={cpi_code} className="form-control" />
          </div>
          <div className="col-md-6">
            <input type="text" name="phone" placeholder="Phone" ref={node => phone = node} defaultValue={phone} className="form-control" />
          </div>
        </div>
        <br/>
        <div className="text-center">
          <button className="btn btn-cancel" type="button" onClick={this.props.hideModal}>Cancel</button>&nbsp;&nbsp;
          <button className="btn btn-primary">Submit</button>
        </div>
      </form>
    )
  }
}

ClinicForm.propTypes = {
  hideModal: PropTypes.func.isRequired,
  clinics: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  clinicData: PropTypes.object
}

const mapStateToProps = state => {
  const { clinics, isLoading } = { ...state.clinics }
  return {
    clinics,
    isLoading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    hideModal: () => { dispatch(hideModal()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClinicForm);