import { UPDATE_CLINIC, CREATE_CLINIC, REMOVE_CLINIC, REQUEST_CLINIC, FETCH_CLINICS, REQUEST_FAILURE } from '../actions/constants';

const initialState = {
    clinics: {},
    isLoading: true
}

export default function clinicReducer(state = initialState, action) {
    switch(action.type){
        case REQUEST_CLINIC:
            return {
                ...state, 
                isLoading: true
            }
        case FETCH_CLINICS:
            return {
                ...state, 
                isLoading: false, 
                ...action.clinics
            };
        case CREATE_CLINIC:
        return {
            ...state, 
            isLoading: false, 
            clinics:[...state.clinics, Object.assign({}, action.clinic)]
        };
        case UPDATE_CLINIC:
            const updatedClinic = state.clinics.map( (clinic, index) => {
                if(clinic.id !== action.clinic.id) {
                    return clinic;
                }
            
                return {
                    ...action.clinic
                };    
            });

            return {
                ...state, 
                isLoading: false, 
                clinics: updatedClinic
            };
        case REMOVE_CLINIC:
            return { 
                ...state, 
                isLoading: false, 
                clinics: [...state.clinics.filter((clinic) => { return clinic.id !== action.clinicId})] 
            };
        default:
            return state
    }
}