import { UPDATE_ALTERATION_CODE, CREATE_ALTERATION_CODE, REMOVE_ALTERATION_CODE, REQUEST_ALTERATION_CODE, FETCH_ALTERATION_CODES } from '../actions/constants';

const initialState = {
  alteration_codes: {},
  isLoading: true
}

export default function alterationCodeReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_ALTERATION_CODE:
      return {
        ...state,
        isLoading: true
      }
    case FETCH_ALTERATION_CODES:
      return {
        ...state,
        isLoading: false,
        ...action.alterationCodes
      };
    case CREATE_ALTERATION_CODE:
      return {
        ...state,
        isLoading: false,
        alteration_codes: [...state.alteration_codes, Object.assign({}, action.alterationCode)]
      };
    case UPDATE_ALTERATION_CODE:
      const updatedAlterationCode = state.alteration_codes.map((alterationCode, index) => {
        if (alterationCode.id !== action.alterationCode.id) {
          return alterationCode;
        }

        return {
          ...action.alterationCode
        };
      });

      return {
        ...state,
        isLoading: false,
        alteration_codes: updatedAlterationCode
      };
    case REMOVE_ALTERATION_CODE:
      return {
        ...state,
        isLoading: false,
        alteration_codes: [...state.alteration_codes.filter((alterationCode) => { return alterationCode.id !== action.alterationCodeId })]
      };
    default:
      return state
  }
}