import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { showModal, hideModal } from '../actions/modalAction';
import { requestAlterationCodes, createAlterationCode } from '../actions/alterationCodeAction';
import AlterationCodeForm from './forms/AlterationCodeForm';
import moment from 'moment';
import ReactTable from "react-table";

class AlterationCodeAdmin extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.requestAlterationCodes();
  }

  renderLoading = () => {
    return (
      <div className="text-center">
        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
      </div>
    )
  }

  isCodeExpired = (code) =>
  {
     return (moment(code.expires_at) < moment() && !code.is_indefinite);
  }

  codeExpiration = (code) =>
  {
    if (code.is_indefinite)
      return 'Indefinite';
    else
      return moment(code.expires_at).format('ll');
  }

  renderTable = (data) => {
    return (
      <div>
        <button className="btn btn-primary pull-right" type="button" onClick={() => this.props.showModal('Alteration Code', 'CREATE_ENTITY')}><i className="fa fa-plus icon" />&nbsp;Create Alteration Code</button>
        <br />
        <br />
        <ReactTable
            data={data}
            noDataText="No matching codes found"
            columns={[
              {
                Header: "Alteration Code",
                id: "code",
                accessor: d => d.code,
                filterable: true,
                headerClassName: 'header',
                filterMethod: (filter, row) => {
                  return row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
                },
                Cell: row => (
                  <div className={this.isCodeExpired(row.original) ? 'data-row expired-code' : 'data-row'} onClick={(data, type) => this.props.showModal({ rowData: row.original, group:'Alteration Code'}, 'UPDATE_ENTITY')}>
                    {row.original.code}
                  </div>
                )
              },
              {
                Header: "Expiration Date",
                headerClassName: 'header',
                Cell: row => (
                  <div className={this.isCodeExpired(row.original) ? 'data-row expired-code' : 'data-row'}>
                    {this.codeExpiration(row.original)}
                  </div>
                )
              },
              {
                Header: "",
                headerClassName: 'header',
                Cell: row => (
                  <div className="expired-code">
                    {this.isCodeExpired(row.original) ? 'Expired!' : ''}
                  </div>
                )
              },
              {
                maxWidth: 25,
                headerClassName: 'header',
                Cell: row => (
                  <div className="pull-right">
                    <i className="fa fa-times fa-lg icon pull-right" onClick={(data, type) => this.props.showModal({ id: row.original.id, name: ' this alteration code', group: 'Alteration Code' }, 'REMOVE_ENTITY')} />
                  </div>
                )
              }
            ]}
            defaultPageSize={15}
            showPageSizeOptions={false}
            getNoDataProps={() => {return {
              style: { backgroundColor: '#f0f0f0', fontWeight: 'bold'}
            }}}
            className="-striped -highlight"
          />
        </div>
    )
  }

  render() {
    return (
      <div>
        <div className="panel-body">
          {(() => {
              if(this.props.isLoading)
                  return this.renderLoading();
              else {
                if(Object.keys(this.props.alteration_codes).length > 0)
                  return this.renderTable(this.props.alteration_codes);
                else
                  return <AlterationCodeForm handleSubmission={this.props.createAlterationCode} />
              }
            })
          ()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { alteration_codes, isLoading } = { ...state.alteration_codes }
  return {
    alteration_codes,
    isLoading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    requestAlterationCodes: () => { dispatch(requestAlterationCodes()) },
    createAlterationCode: (alterationCode) => { dispatch(createAlterationCode(alterationCode)) },
    showModal: (data, type) => { dispatch(showModal(data, type)) },
    hideModal: () => { dispatch(hideModal()) }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AlterationCodeAdmin));
