import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClinicForm from './forms/ClinicForm';
import { requestClinics, createClinic } from '../actions/clinicAction';
import ReactTable from "react-table";
import { showModal, hideModal } from '../actions/modalAction';

class ClinicAdmin extends Component {

  constructor(props){
    super(props);
  }

  renderLoading = () => {
    return (
      <div className="text-center">
        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
      </div>
    )
  }

  componentDidMount() {
      this.props.requestClinics();
  }

  filterNames = (filter, row) =>
  {
    return row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
  }

  renderTable = (data) => {
    return (
      <div>
        <button className="btn btn-primary pull-right" type="button" onClick={() => this.props.showModal('Clinic', 'CREATE_ENTITY')}><i className="fa fa-plus icon" />&nbsp;Add New Clinic</button>
        <br />
        <br />
        <ReactTable
            data={data}
            noDataText="No matching clinics found"
            columns={[
              {
                Header: "Clinic",
                id: "clinic",
                accessor: d => d.name,
                filterable: true,
                headerClassName: 'header',
                filterMethod: (filter, row) => {
                  return this.filterNames(filter, row);
                },
                Cell: row => (
                  <div className="data-row" onClick={(data, type) => this.props.showModal({ rowData: row.original, group:'Clinic'}, 'UPDATE_ENTITY')}>
                    {row.original.name}
                  </div>
                )
              },
              {
                Header: "City",
                id: "city",
                accessor: d => d.city,
                filterable: true,
                filterMethod: (filter, row) => {
                  return this.filterNames(filter, row);
                },
                headerClassName: 'header'
              },
              {
                Header: "State",
                id: "state",
                accessor: d => d.state,
                filterable: true,
                filterMethod: (filter, row) => {
                  return this.filterNames(filter, row);
                },
                headerClassName: 'header'
              },
              {
                maxWidth: 20,
                headerClassName: 'header',
                Cell: row => (
                  <div className="pull-right">
                    <i className="fa fa-times fa-lg icon pull-right" onClick={(data, type) => this.props.showModal({ id: row.original.id, name: row.original.name, group: 'Clinic' }, 'REMOVE_ENTITY')} />
                  </div>
                )
              }
            ]}
            defaultPageSize={15}
            showPageSizeOptions={false}
            getNoDataProps={() => {return {
              style: { backgroundColor: '#f0f0f0', fontWeight: 'bold'}
            }}}
            className="-striped -highlight"
          />
        </div>
    )
  }

  render() {
    return (
      <div>
      { this.props.isLoading ? this.renderLoading() : Object.keys(this.props.clinics).length > 0 ? this.renderTable(this.props.clinics) : <ClinicForm handleSubmission={this.props.createClinic} /> }
      </div>
    )
  }
}

ClinicAdmin.propTypes = {
  requestClinics: PropTypes.func.isRequired,
  createClinic: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  const { clinics, isLoading } =  {...state.clinics}
  return {
    clinics,
    isLoading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    requestClinics: () => { dispatch(requestClinics()) },
    createClinic: (clinic) => { dispatch(createClinic(clinic)) },
    showModal: (data, type) => { dispatch(showModal(data, type)) },
    hideModal: () => { dispatch(hideModal()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClinicAdmin);
