import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { hideModal } from '../../actions/modalAction';
import { removeUser } from '../../actions/userAction';
import { removeClinic } from '../../actions/clinicAction';
import { removeFile } from '../../actions/firmwareAction';
import { removeAlterationCode } from '../../actions/alterationCodeAction';

class RemoveModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const entityId = this.props.entityId;
        return (
        <Modal show={this.props.showModal} onHide={this.props.hideModal}>
            <Modal.Header closeButton>
                <Modal.Title>Remove {this.props.group}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={e => {
                    e.preventDefault();

                    switch (this.props.group) {
                        case 'User':
                            this.props.removeUser(entityId);
                            break;
                        case 'Clinic':
                            this.props.removeClinic(entityId);
                            break;
                        case 'Firmware':
                            this.props.removeFile(entityId)
                            break;
                        case 'Alteration Code':
                            this.props.removeAlterationCode(entityId)
                            break;
                        default:
                            alert("There isn't a method for that action");
                    }

                    e.target.reset();

                    this.props.hideModal();
                }} className="form-horizontal">
                    Are you sure you want to delete {this.props.name}?
                    <br /><br />
                    <div className="text-center">
                        <button className="btn btn-primary" type="button" onClick={this.props.hideModal}>No</button>&nbsp;&nbsp;
                        <button className="btn">Yes</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
      )
    }
}

RemoveModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    removeClinic: PropTypes.func.isRequired,
    removeUser: PropTypes.func.isRequired,
    entityId: PropTypes.number.isRequired,
    group: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    showModal: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
    const { modalProps } = {...state.modals};
    return {
      entityId: modalProps.modalData.id,
      group: modalProps.modalData.group,
      name: modalProps.modalData.name,
      showModal: modalProps.showModal
    }
  }

  const mapDispatchToProps = dispatch => {
    return {
      hideModal: () => { dispatch(hideModal()) },
      removeClinic: (clinicId) => { dispatch(removeClinic(clinicId)) },
      removeUser: (userId) => { dispatch(removeUser(userId)) },
      removeFile: (firmwareId) => { dispatch(removeFile(firmwareId)) },
      removeAlterationCode: (alterationCodeId) => { dispatch(removeAlterationCode(alterationCodeId)) }
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(RemoveModal);
