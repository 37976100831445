import { UPLOAD_FILE, REMOVE_FILE, REQUEST_FILE, FETCH_FILES} from '../actions/constants';

const initialState = {
  firmwares: {},
  isLoading: true
}

export default function firmwareReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_FILE:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_FILES:
      return {
        ...state,
        isLoading: false,
        ...action.firmwares
      };
    case UPLOAD_FILE:
      return {
        ...state,
        isLoading: false,
        firmwares: [...state.firmwares, Object.assign({}, action.firmware)]
      };
    case REMOVE_FILE:
      return {
        ...state,
        isLoading: false,
        firmwares: [...state.firmwares.filter((firmware) => { return firmware.id !== action.firmwareId })]
      };
    default:
      return state
  }
}