import { REQUEST_ELBOW_VERSION, FETCH_ELBOW_VERSIONS} from '../actions/constants';

const initialState = {
  elbow_versions: {},
  isLoading: true
}

export default function elbowVersionReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_ELBOW_VERSION:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_ELBOW_VERSIONS:
      return {
        ...state,
        isLoading: false,
        ...action.elbow_versions
      };
    default:
      return state
  }
}