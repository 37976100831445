//  CLINIC
export const CREATE_CLINIC = 'CREATE_CLINIC';
export const UPDATE_CLINIC = 'UPDATE_CLINIC';
export const REMOVE_CLINIC = 'REMOVE_CLINIC';
export const REQUEST_CLINIC = 'REQUEST_CLINIC'; 
export const FETCH_CLINICS = 'FETCH_CLINICS';

//  MODAL
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

//  USER
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const REQUEST_USER = 'REQUEST_USER'; 
export const FETCH_USERS = 'FETCH_USERS';

//  NOTIFICATION
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';

//  LOGIN
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOG_OUT = 'LOG_OUT';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const TOKEN_EXPIRED = 'TOKEN_EXPIRED';
export const RESET_USER_PASSWORD_SUCCESS = 'RESET_USER_PASSWORD_SUCCESS';

//  FIRMWARE
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const REMOVE_FILE = 'REMOVE_FILE';
export const REQUEST_FILE = 'REQUEST_FILE';
export const FETCH_FILES = 'FETCH_FILES';

//  ALTERATION CODES
export const CREATE_ALTERATION_CODE = 'CREATE_ALTERATION_CODE';
export const UPDATE_ALTERATION_CODE = 'UPDATE_ALTERATION_CODE';
export const REMOVE_ALTERATION_CODE = 'REMOVE_ALTERATION_CODE';
export const REQUEST_ALTERATION_CODE = 'REQUEST_ALTERATION_CODE';
export const FETCH_ALTERATION_CODES = 'FETCH_ALTERATION_CODES';

//  ELBOW VERSIONS
export const REQUEST_ELBOW_VERSION = 'REQUEST_ELBOW_VERSION';
export const FETCH_ELBOW_VERSIONS = 'FETCH_ELBOW_VERSIONS';